import "@fullcalendar/core/vdom";
import ptBr from "@fullcalendar/core/locales/pt-br";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";

import store from "@/store";
import { IFullCalendarEvent } from "@/interfaces/full_calendar_event";
import { format } from "date-fns";
import { AppointmentModality } from "@/interfaces/appointment";

const IGNORE_CLOSE_CLASS = "ignore-close";

const htmlToElementNode = (html: string) => {
	const temp = document.createElement("template");
	temp.innerHTML = html.trim();
	return temp.content.firstElementChild;
};

enum EViews {
	listWeek = "listWeek",
	timeGridWeek = "timeGridWeek",
	dayGridMonth = "dayGridMonth",
}

const SAFE_TITLE_INJECTION_CLASS = "fc-event-title-injection";
const ModalityIconMap = {
	[AppointmentModality.IN_PERSON]: "presential",
	[AppointmentModality.TELEMEDICINE]: "telemedicine",
};
const getNodesElementFromView = (
	type: EViews,
	start: Date,
	end: Date,
	title: string,
	isTaken: boolean,
	modality: AppointmentModality,
	color: string,
) => {
	//if "isEndAtNextDay" is true, the calendar display style is changed automatically
	const isEndAtNextDay = end.getDate() > start.getDate() && end.getMinutes() !== 0;

	const brightness = isEndAtNextDay && type !== EViews.listWeek ? "filter: brightness(999);" : "";
	let modalityNode = "";

	if (modality) {
		const icon = ModalityIconMap[<keyof AppointmentModality>modality];
		const variant = isTaken ? "red-" : "";
		const margin = type !== EViews.listWeek ? "margin-right: 6px;" : "";
		modalityNode = `<img class="w-4 p-0 ${IGNORE_CLOSE_CLASS}" src="${require(`../../assets/icons/${variant}${icon}.svg`)}" style="${margin} ${brightness}"></img>`;
	}

	const htmlElements = {
		[EViews.listWeek]: () => `
    <div>
      <a tabindex="0" class="flex items-center gap-2 w-min ${IGNORE_CLOSE_CLASS}">
        ${modalityNode}
        <span class="whitespace-nowrap ${SAFE_TITLE_INJECTION_CLASS} ${IGNORE_CLOSE_CLASS}"></span>
      </a>
    </div>`,
		[EViews.timeGridWeek]: () => `
      <div>
        <div class="fc-event-main">
          <div class="fc-event-main-frame">
            <div class="fc-event-time">${format(start, "HH:mm")} - ${format(end, "HH:mm")}</div>
            <div class="fc-event-title-container">
              <div class="fc-event-title fc-sticky ${SAFE_TITLE_INJECTION_CLASS}"></div>
            </div>
          </div>
        </div>
      <div>
      `,
		[EViews.dayGridMonth]: () => {
			const titleNode = `
      <div class="fc-event-title items-center" style="display: flex; margin-left: 4px;${isEndAtNextDay ? "" : ""}"}>
        ${modalityNode}
        <div class="${SAFE_TITLE_INJECTION_CLASS}"></div>
      </div>
      `;

			return `
        <div>
          <div class="flex items-center flex-nowrap">
            <div class="fc-daygrid-event-dot" style="margin: 0 6px; border-color: ${
							isEndAtNextDay && type !== EViews.listWeek ? "white" : color
						};"></div>
            <div class="fc-event-time min-w-max">${format(start, "HH:mm")}</div>
            ${isEndAtNextDay ? titleNode : ""}
            </div>
          ${isEndAtNextDay ? "" : titleNode}
        </div>
      `;
		},
	};

	const node = htmlToElementNode(htmlElements[type]())!;
	node.querySelector(`.${SAFE_TITLE_INJECTION_CLASS}`)!.textContent = title;

	return node.childNodes;
};

const scrollWidth = 5;
function replaceAllEvents(newEvents: IFullCalendarEvent[]) {
	const calendarApi = store.state.appConfig.layout.calendarApi;
	const currentEvents = calendarApi.getEvents();
	currentEvents.forEach((event: any) => {
		event.remove();
	});

	newEvents.forEach((event: any) => {
		calendarApi.addEvent(event);
	});
}

let loadingId = "";
function setFullCalendarLoading(loadingId: string, isLoading: boolean) {
	const loading: HTMLElement = document.getElementById(loadingId)!;
	if (isLoading) {
		Object.assign(loading.style, { visibility: "visible", opacity: 1 });
	} else {
		Object.assign(loading.style, { visibility: "hidden", opacity: 0 });
	}
}

function closeBalloonFunction(event: any) {
	const { target } = event;
	const { isOpened } = store.state.appConfig.layout.agendaBalloon;
	const classesToIgnoreClick = ["fc-daygrid-event", "fc-timegrid-event", IGNORE_CLOSE_CLASS];
	const shouldIgnoreClose = classesToIgnoreClick.some(item => target.classList.contains(item));

	if (!shouldIgnoreClose && isOpened) {
		updateAgendaBalloon({ isOpened: false, isHovered: false });
	}
}

function updateAgendaBalloon(data: any) {
	store.commit("appConfig/UPDATE_AGENDA_BALLOON", data);
}

function setBalloonPosition(element: any) {
	const currentElement = element.querySelector("a") ?? element;
	const elementWidth = currentElement.offsetWidth;
	const { top, left } = currentElement.getBoundingClientRect();
	const { scrollTop: pageScrollTop, scrollLeft: pageScrollLeft } = document.getElementById("app-content")!;

	const eventBalloon = document.getElementById("event-balloon")!;
	const offsetX = (eventBalloon.offsetWidth - elementWidth) / 2;
	const offsetY = eventBalloon.offsetHeight + 15;

	let finalLeft = Math.ceil(left + pageScrollLeft - offsetX);
	const finalTop = Math.ceil(top + pageScrollTop - offsetY);

	const balloonWidth = eventBalloon.offsetWidth;
	const windowWidth = window.innerWidth;

	if (finalLeft + balloonWidth + scrollWidth > windowWidth) {
		const offset = finalLeft + balloonWidth + scrollWidth - windowWidth;
		finalLeft -= offset;
	}

	if (finalLeft < scrollWidth) {
		finalLeft = scrollWidth;
	}

	Object.assign(eventBalloon.style, {
		left: `${finalLeft}px`,
		top: `${finalTop}px`,
	});
}

let range = {};
let lastFiltersState: Record<string, any> = {};
const componentDataLiteral: any = {};

export const AgendaCoreMixin = (
	id: string,
	fetchEvents: (range: any, params?: any, componentData?: Record<string, any>) => Promise<IFullCalendarEvent[]> | any[],
	filtersMap?: Record<string, string>,
	componentDataArray?: string[],
) => {
	return {
		data() {
			return {
				calendarOptions: {
					plugins: [dayGridPlugin, listPlugin, timeGridPlugin],
					initialView: "dayGridMonth",
					height: "100%",
					weekends: true,
					dayMaxEvents: 2,
					eventMaxStack: 3,
					eventOrder: "start",
					eventOrderStrict: true,
					eventTimeFormat: {
						hour: "numeric",
						minute: "2-digit",
						meridiem: false,
					},
					views: {
						dayGridMonth: { titleFormat: { year: "numeric", month: "long" } },
						timeGridWeek: { titleFormat: { year: "numeric", month: "long", day: "2-digit" } },
						listWeek: { titleFormat: { year: "numeric", month: "long", day: "2-digit" } },
					},
					locale: ptBr,
					nowIndicator: true,
					headerToolbar: {
						start: "title",
						center: "",
						end: `${filtersMap ? "applyFilters " : ""}${EViews.listWeek},${EViews.timeGridWeek},${
							EViews.dayGridMonth
						} today prev,next`,
					},
					buttonText: {
						today: "Hoje",
						month: "Mensal",
						week: "Semanal",
						list: "Lista",
						day: "Diária",
					},
					customButtons: {
						applyFilters: {
							text: "Aplicar filtros",
							click: (<any>this).applyFilters.bind(this, filtersMap),
						},
					},
					events: async ({ startStr: start, endStr: end }: any, success: Function, failure: Function) => {
						try {
							setFullCalendarLoading(loadingId, true);
							range = { start, end };
							const events = await fetchEvents(range, lastFiltersState, componentDataLiteral);
							replaceAllEvents([]); //removes all events
							success(events);
						} catch (err) {
							failure(err);
						} finally {
							setFullCalendarLoading(loadingId, false);
						}
					},
					eventClassNames: ({ event }: any) => {
						const { isTaken, isScheduleView } = event.extendedProps;
						if (isTaken && !isScheduleView) {
							return ["fc-event-taken"];
						}
					},
					eventContent: ({ view, event }: any) => {
						const { start, end, title, extendedProps, borderColor } = event;
						const { isTaken = false, isScheduleView, modality } = extendedProps;
						const { type }: { type: EViews } = view;

						return {
							domNodes: getNodesElementFromView(
								type,
								start,
								end,
								title,
								isTaken && !isScheduleView,
								modality,
								borderColor,
							),
						};
					},
					eventMouseEnter: async ({ el: element, event, view, jsEvent }: any) => {
						if (store.state.appConfig.layout.agendaBalloon.isOpened) {
							return;
						}

						updateAgendaBalloon({ event, isHovered: true });
						//WARN guarantees that the commit is made and the element has reacted
						await (<any>this).$nextTick();

						setBalloonPosition(element);
					},
					eventMouseLeave: ({ el: element, event, view, jsEvent }: any) => {
						if (!store.state.appConfig.layout.agendaBalloon.isOpened) {
							updateAgendaBalloon({ isHovered: false });
						}
					},
					eventClick: async ({ el: element, event, view, jsEvent }: any) => {
						updateAgendaBalloon({ isOpened: true, isHovered: true, event });
						await (<any>this).$nextTick();

						setBalloonPosition(element);
					},
				},
			};
		},
		methods: {
			async applyFilters() {
				const filters = this.getFilters();

				if (JSON.stringify(lastFiltersState) === JSON.stringify(filters)) {
					return;
				}

				lastFiltersState = filters;
				setFullCalendarLoading(loadingId, true);
				const newEvents = await fetchEvents(range, filters);
				replaceAllEvents(newEvents);
				setFullCalendarLoading(loadingId, false);
			},

			hideApplyFiltersButton() {
				store.state.appConfig.layout.calendarApi.setOption("headerToolbar", {
					end: `${EViews.listWeek},${EViews.timeGridWeek},${EViews.dayGridMonth} today prev,next`,
				});
			},

			setComponentDataLiteral() {
				componentDataArray?.forEach(item => {
					const value = (<any>this)[item];
					if (![undefined, null].includes(value)) {
						componentDataLiteral[item] = value;
					}
				});
			},

			getFilters() {
				const filters: Record<string, any> = {};
				if (filtersMap) {
					Object.keys(filtersMap).forEach(key => {
						const componentValue = (<any>this)[filtersMap[key]];
						if (![undefined, null].includes(componentValue)) {
							filters[key] = componentValue;
						}
					});
				}

				return filters;
			},

			setFilters() {
				lastFiltersState = this.getFilters();
			},
		},
		created() {
			loadingId = `${id}-calendar-loading`;
			lastFiltersState = {};
		},
		mounted() {
			updateAgendaBalloon({ isMounted: true });
			document.getElementById("app-content")!.addEventListener("click", closeBalloonFunction);
			store.commit("appConfig/SET_CALENDAR_API", (<any>this).$refs.fullCalendar.getApi());
		},
		destroyed() {
			updateAgendaBalloon({ isMounted: false });
			document.getElementById("app-content")!.removeEventListener("click", closeBalloonFunction);
		},
	};
};
